/*****************************
  Shortcodes
*****************************/

/*****************************
  Accordion
*****************************/

.accordion {
	.card {
		border: none;
		&:last-child {
			.card-header {
				margin-bottom: 0;
			}
		}
	}
	.card-body {
		padding: 35px 45px 45px 62px;
	}
	.card-header {
		background: none;
		border-bottom: none;
		padding: 0;
	}
	>.card {
		.card-header {
			margin-bottom: 10px;
		}
	}
	.accordion-icon.card-header {
		button {
			display: flex;
			background: $primary;
			font-size: 18px;
			color: $white;
			padding: 32px 30px 32px 62px;
			width: 100%;
			text-align: left;
			border: none;
			font-weight: normal;
			position: relative;
			border-radius: $border-radius;
			font-weight: 500;
		}
		.btn {
			&:after {
				content: "\f062";
				margin-left: auto;
				transform: rotate(0deg);
				font-family: "Font Awesome 5 Free";
				font-weight: bold;
			}
		}
		button.collapsed {
			background: $gray-9;
			&:after {
				transform: rotate(180deg);
			}
		}
	}
}
