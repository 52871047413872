/*****************************
  Countdown
*****************************/
.countdown-wrapper{
		border-radius: 5px;
    overflow: hidden;
  }

.countdown {

	padding: 20px;
	width: 140px;
	position: relative;

	span {
		font-size: 54px;
		line-height: 60px;
		font-weight: 600;
		color: $white;
	}

	p {
		font-size: 18px;
		text-transform: capitalize;
		margin-bottom: 0;
		color: $gray-8;
	}

}

.countdown.countdown-last{
	position: absolute;
	bottom: 0;
	left: 140px;
}

.countdown-small {
	span {
		font-size: 24px;
		line-height: 24px;
	}

	p {
		font-size: 14px;
	}

}

@media (max-width: 767px) {

.countdown {

	span {
			font-size: 34px;
			line-height: 40px;
		}

		p {
			font-size: 16px;
		}

	}

}
